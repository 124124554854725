/* ************************
   03.5: Coming Soon
   ********************* */

   .coming-soon-content {
      h1 {
         font-size: 72px;
         margin-bottom: 12px;
      }

      p {
         font-size: 18px;
      }

      @include mobileLg {
         br {
            display: none;
         }
      }
      h5 {
         margin-bottom: 28px;
      }
   }

   #countdown {
      max-width: 648px;
      margin: 44px auto 13px;
      @include mobileMd {
         display: flex;
         flex-wrap: wrap;
      }
      li {
         margin-bottom: 40px;
         @include mobileMd {
            flex: 1 1 50%;
            &.seperator {
               display: none;
            }
         }
      }
      .single-countdown {
         h2 {
            font-size: 60px;
         }
         span {
            @extend %heading-font;
            @extend %title-color;
            font-size: 18px;
            text-transform: uppercase;
         }
      }
   }