/* ************************
   04.2: Service
   ********************* */

    .service {
        @include mobileLg {
            background-position: left;
        }
        .section-title {
            @include tab {
                br {
                    display: none;
                }
            }
        }
    }

    .service-left {
        @include tab {
            margin-bottom: 60px;
        }
    }

    .m-salon-service {
        @include tab {
            background-position: 0 0;
        }
    }

    .single-service {
        margin-bottom: 78px;
        text-align: center;
        .icon {
            margin-bottom: 35px;
            img, svg, i {
                width: auto;
                margin: 0 auto;
            }
        }
        .content {
            h3 {
                margin-bottom: 12px;
            }
            p {
                max-width: 260px;
                margin: 0 auto;
            }
        }
        &.text-white {
            .content {
                h3, p {
                    @extend %white;
                }
            }
        }
        &.text-left {
            text-align: left;
            .content {
                p {
                    margin: 0;
                }
            }
        }
        &.service-box {
            box-shadow: 5px 5px 45px rgba(#000, 0.05);
            @extend %white-bg;
            @extend %trans3;
            padding: 45px 15px;
            margin-bottom: 30px;

            .icon {
                svg {
                    path {
                        fill: $title-color;
                    }
                    &.stroke {
                        path {
                            stroke: $title-color;
                        }
                    }
                }
            }
            
            .content {
                h3 {
                    @extend %trans3;
                    &:hover {
                        @extend %c1;
                    }
                }
            }
            &:hover,
            &.active {
                box-shadow: 7px 7px 60px rgba(#000, 0.07);
                .icon {
                    svg {
                        path {
                            fill: $c1;
                        }
                        &.stroke {
                            path {
                                stroke: $c1;
                            }
                        }
                    }
                }
            }
        }
    }

    .card-box-style {
        .single-service {
            margin-bottom: 0;
            text-align: left;
            .icon {
                width: 80px;
                height: 80px;
                border: 1px dashed #F79457;
                @extend %radius;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                img, svg, i {
                    width: auto;
                    margin: 0;
                }
            }
            .content {
                h3 {
                    margin-bottom: 12px;
                }
                p {
                    max-width: inherit;
                    margin: 0;
                }
            }
            // &.active {
            //     @extend %white-bg;
            //     @extend %box-shadow3;
            //     box-shadow: 0 0 5px #000000;
            //     border-color: transparent;
            //     padding: 55px 35px 50px 27px;
            //     // padding: 55px 45px 50px;
            //     transform: scale(1.05);
            //     position: relative;
            //     z-index: 1;
            // }
        }
    }

    .service-img {
        @include tab {
            margin-bottom: 60px;
        }
        img {
            margin-top: -220px;
            @include tab {
                margin-top: 0;
            }
        }
    }
    .service-content {
        margin-bottom: -60px;
        &.style--two {
            @include tab {
                margin-bottom: 0;
            }
        }
        .single-service {
            margin-bottom: 56px;
            .content {
                h3 {
                    margin-bottom: 10px;
                }
            }
        }
    }


    /* Service Point */
    .service-point-content {
        @include tab {
            margin-bottom: 80px;
        }
        .section-title {
            p {
                font-size: 18px;
                max-width: 430px;
            }
        }
    }
    
    .service_point-map-img {
        position: relative;
        margin-left: -22px;
        img {
            max-width: none;
        }
        @include medium {
            display: inline-block;
            margin-left: 0;
            img {
                max-width: 100%;
            }
        }
        .l_info {
            position: absolute;
            left: 45px;
            top: 35px;
    
            &.l_info2 {
                left: 38%;
                top: auto;
                bottom: 32%;
            }
            &.l_info3 {
                left: 45%;
                top: 10px;
            }
            &.l_info4 {
                left: 66%;
                top: 46%;
            }
            &.l_info5 {
                left: auto;
                right: -100px;
                top: 25px;
            }
            &.l_info6 {
                left: auto;
                top: auto;
                right: -16%;
                bottom: 25%;
            }
    
            @include medium {
                left: 35px;
                top: 30px;
    
                &.l_info2 {
                    left: 30%;
                    top: auto;
                    bottom: 32%;
                }
                &.l_info3 {
                    left: 35%;
                    top: 3px;
                }
                &.l_info4 {
                    left: 50%;
                    top: 45%;
                }
                &.l_info5 {
                    left: auto;
                    right: 60px;
                    top: 22px;
                }
                &.l_info6 {
                    left: auto;
                    top: auto;
                    right:10%;
                    bottom: 23%;
                }
            }
    
            .circle-ball {
                display: inline-block;
                width: 10px;
                height: 10px;
                background-color: #6A1A9A;
                border-radius: 50%;
                z-index: 1;
                position: relative;
                cursor: pointer;
                &:after {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: #6A1A9A;
                    border-radius: 50%;
                    z-index: -1;
                    transform: scale(1);
                    animation: ripple2 1.5s 0s infinite;
                }
            }
    
            .info-box {
                background-color: #6A1A9A;
                padding: 20px 30px;
                position: relative;
                @extend %box-shadow;
                position: absolute;
                width: max-content;
                transform: translateX(-50%);
                left: 0;
                bottom: 20px;
                transition: .4s;
                opacity: 0;
                visibility: hidden;
                z-index: 1;
                min-width: 230px;
    
                h3 {
                    margin-bottom: 6px;
                    @extend %white;
                }
                span {
                    @extend %white;
                }
                &:after {
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 10px solid #6A1A9A;
                    position: absolute;
                    content: "";
                    bottom: -7px;
                    left: 50%;
                }
            }
            
            &.active {
                .info-box {
                    opacity: 1;
                    visibility: visible;
                    bottom: 30px;
                }
            }
        }
        @include medium {
            .dvpn_map-img {
                left: 25px;
                img {
                    max-width: initial!important;
                }
            }
        }
        &.style--two {
            .l_info {
        
                .circle-ball {
                    background-color: #FF0000;
                    &:after {
                        background-color: #FF0000;
                    }
                }
        
                .info-box {
                    background-color: #FF0000;
                    &:after {
                        border-top: 10px solid #FF0000;
                    }
                }
            }
        }
    }

    /* Service Details */
    .therapy-content {
        h2 {
            font-size: 36px;
            margin-bottom: 15px;
        }

        .therapy-list {
            margin-top: 25px;
        }
    }

    .therapy-thumb {
        position: relative;
        .video-btn {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &.style--two {
            margin-top: -220px;
            @include customMax(1199) {
                margin-top: 0;
                display: inline-block;
            }
        }
    }

    .service-quote-img {
        margin-bottom: 42px;
    }

    .service-details-content {
        h2 {
            font-size: 36px;
            margin-bottom: 15px;
        }
        .thumb-wrap {
            margin-bottom: 40px;
        }
        .video-wrap {
            position: relative;
            .video-btn {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }