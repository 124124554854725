/* ************************
   03.6: Banner
   ********************* */

   .banner-single-slide {
      background-color: #FBE6EA;
      padding: 180px 0 100px;
      min-height: calc(100vh - 50px);
      background-position: bottom center;
      display: flex;
      align-items: center;
      @include mobileSm {
         min-height: 800px;
         padding-bottom: 160px;
      }
   }

   .banner-slider {
      background-color: #fbe6ea;
      .banner-single-slide {
         @include mobileLg {
            background-position: left;
            padding-bottom: 150px;
         }
      }
   }
   .banner-slider2 {
      .banner-single-slide {
         background-color: transparent;
         min-height: 100vh;
         @include mobileLg {
            background-position: left;
            padding-bottom: 100px;
         }
      }
   }
   .banner-slider3 {
      .banner-single-slide {
         min-height: 100vh;
         background-position: center;
         @include mobileLg {
            background-position: left;
            min-height: initial;
         }
         .banner-content {
            h1 {
               line-height: 1.28;
               position: relative;
               padding-bottom: 14px;
               margin-bottom: 26px;
               text-transform: capitalize;
               span {
                  color: #F79457;
               }
               &:after {
                  width: 80px;
                  height: 3px;
                  left: 0;
                  bottom: 0;
                  background-color: #F79457;
                  position: absolute;
                  content: "";
               }
            }
            @include mobileSm {
               h1 {
                  font-size: 46px;
               }
            }
         }
      }
   }
   .banner-slider4 {
      .banner-single-slide {
         @include mobileMd {
            background-position: left;
            min-height: 680px;
            padding-bottom: 100px;
         }
      }
      .banner-content {
         h3 {
            color: #6A1A9A;
            margin-bottom: 12px;
            @extend %regular;
         }
      }
   }
   .banner-slider5 {
      .banner-single-slide {
         @extend %title-color-bg;
         height: 100vh;
         padding: 220px 0 100px;
         position: relative;
         z-index: 1;
         @include mobileMd {
            min-height: auto;
            padding-bottom: 100px;
         }
         &:after {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            content: "";
            @extend %title-color-bg;
            position: absolute;
            opacity: 0.3;
            z-index: -1;
         }
      }
      .banner-content {
         text-align: center;
         * {
            @extend %white;
         }
         h2 {
            margin-bottom: 4px;
            @extend %regular;
            font-size: 48px;
            @include tab {
               font-size: 36px;
               margin-bottom: 10px;
            }
         }
         h1 {
            @extend %bold;
            font-size: 100px;
            text-transform: uppercase;
            margin-bottom: 14px;
            @include tab {
               font-size: 70px;
            }
            @include mobileLg {
               font-size: 50px;
            }
         }
         p {
            font-size: 18px;
            margin-bottom: 0;
            @include tab {
               font-size: 16px;
            }
         }
      }
   }

   .banner-content {
      h6 {
         @extend %regular;
         @extend %c1;
         @extend %radius-50;
         background-color: rgba($c1, 0.1);
         padding: 9px 14px 5px;
         display: inline-block;
         text-transform: capitalize;
         margin-bottom: 20px;
      }
      h4 {
         @extend %c1;
         @extend %regular;
         margin-bottom: 18px;
      }
      h1 {
         margin-bottom: 18px;
         font-size: 70px;
         @include mobileSm {
            font-size: 60px;
         }
      }
      p {
         margin-bottom: 42px;
         font-size: 18px;
      }
      @include mobileLg {
         br {
            display: none;
         }
      }
   }

   .banner-btn-group {
      display: flex;
      align-items: center;
      @include mobileSm {
         flex-direction: column;
         align-items: flex-start;
      }
      .btn {
         &:not(:last-child) {
            margin-right: 12px;
            @include mobileSm {
               margin-right: 0;
               margin-bottom: 10px;
            }
         }
      }
      &.style2 {
         @include mobileSm {
            flex-direction: row;
            align-items: center;
         }
         .btn {
            &:not(:last-child) {
               margin-right: 12px;
               @include mobileSm {
                  margin-right: 12px;
                  margin-bottom: 0px;
               }
            }
         }
      }
   }
   
   .banner-socials2 {
      width: 100px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
      @extend %white-bg;
      display: flex;
      align-items: center;
      justify-content: center;
      @include customMax(1340) {
         display: none;
      }
      .social-inner {
         display: flex;
         transform: rotate(-90deg);
         padding-left: 43px;
      }
      a {
         text-transform: uppercase;
         font-size: 13px;
         @extend %heading-font;
         &:not(:last-child) {
            margin-right: 60px;
         }
         &:hover {
            color: #6A1A9A;
         }
      }
      &.style--two {
         left: auto;
         right: 40px;
         width: 10px;
         background-color: transparent;
         display: flex;
         @include tab {
            right: 20px;
         }
         @include mobileLg {
            display: none;
         }
         .social-inner {
            padding-left: 0px;
            padding-right: 120px;
         }
         a {
            @extend %white;
            &:hover {
               color: #FF0000;
            }
         }
      }
   } 

   .banner {
      .socials {
         position: absolute;
         bottom: 45px;
         z-index: 3;
         @include mobileLg {
            bottom: 30px;
         }
      }
      &.style--two {
         .bottom-shape {
            position: absolute;
            left: 0;
            top: auto;
            bottom: -1px;
            width: 100%;
            height: 110px;
            @include tab {
               display: none;
            }
         }
      }
   }


   //Banner Circular Progress
   .circular-progress {
      transform: rotate(270deg);
   }
   .circular-progress circle:nth-of-type(1) {
      stroke: transparent;
   }
   .circular-progress circle {
         stroke-width: 3;
         fill: none;
         stroke-linecap: round;
   }
   .circular-progress circle:nth-of-type(2) {
      stroke: $c1;
      stroke-dasharray: 251.4285714286;
      stroke-dashoffset: 75.4285714286;
   }