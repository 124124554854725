/* ************************
   03.4: 404
   ********************* */

   .content-404 {
      h1 {
         font-size: 140px;
         line-height: 1;
         margin-bottom: 20px;
      }
      h2 {
         line-height: 1.3;
         margin-bottom: 14px;
         @include mobileMd {
            br {
               display: none;
            }
         }
      }
      p {
         font-size: 18px;
         margin-bottom: 40px;
      }

      > .btn {
         margin-top: 20px;
         font-size: 13px;
         padding-left: 20px;
         height: 50px;
         svg {
            width: 37px;
            height: 37px;
         }
         span {
            top: 1px;
         }
      }
   }
