/* ************************
   02.3: Social List
   ********************* */

   .socials {
      display: flex;
      a {
         width: 30px;
         height: 30px;
         @extend %radius;
         border: 1px solid $title-color;
         display: inline-flex;
         justify-content: center;
         align-items: center;
         padding: 5px;
         font-size: 13px;
         &:not(:last-child) {
            margin-right: 12px;
         }
         i {
            transition: .1s;
            position: relative;
            top: 1px;
         }
         &:hover {
            border-color: $c1;
            @extend %c1;
         }
      }
      &.banner-socials {
         a {
            border: none;
            width: auto;
            height: auto;
            &:hover {
               color: #F79457;
            }
         }
      }
      &.style--two {
         a {
            &:not(:last-child) {
               margin-right: 20px;
            }
            width: 45px;
            height: 45px;
            font-size: 17px;
            &:hover {
               color: #F79457;
               border-color: #F79457;
            }
         }
         &.f-salon {
            a {
               &:hover {
                  color: #6A1A9A;
                  border-color: #6A1A9A;
               }
            }
         }
         &.m-salon {
            a {
               @extend %white;
               border-color: $white;
               &:hover {
                  color: #FF0000;
                  border-color: #FF0000;
               }
            }
         }
      }
      &.style--three {
         a {
            &:not(:last-child) {
               margin-right: 25px;
            }
            width: 80px;
            height: 80px;
            font-size: 32px;
            &:hover {
               color: #6A1A9A;
               border-color: #6A1A9A;
            }
            @include customMax(1199) {
               &:not(:last-child) {
                  margin-right: 16px;
               }
               width: 50px;
               height: 50px;
               font-size: 18px;
            }
            @include tab {
               &:not(:last-child) {
                  margin-right: 10px;
               }
               width: 30px;
               height: 30px;
               font-size: 12px;
            }
         }
      }
      &.style--four {
         a {
            &:not(:last-child) {
               margin-right: 14px;
            }
            width: 50px;
            height: 50px;
            font-size: 18px;
            @extend %white;
            border-color: $white;
            &:hover {
               @extend %white-bg;
               border-color: $white;
               color: #FF0000;
            }
            @include customMax(1400) {
               &:not(:last-child) {
                  margin-right: 10px;
               }
               width: 35px;
               height: 35px;
               font-size: 14px;
            }
         }
      }
   }
