/* ************************
   04.5: Specialist
   ********************* */

.single-specialist {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    img {
        width: 100%;
    }
    .content {
        position: absolute;
        left: auto;
        top: auto;
        right: 0;
        bottom: 0;
        width: 80%;
        background-color: rgba($c1, 0.8);
        padding: 28px 30px 26px;
        @extend %trans5;
        transform: translate(100%, 100%);
        opacity: 0;
        h3, p {
            @extend %white;
        }
        h3 {
            margin-bottom: 3px;
            @extend %trans3;
        }
    }
    &:hover,
    &.active {
        .content {
            transform: translate(0%, 0%);
            opacity: 1;
        }
    }
}

/* Single Teacher */
.single-teacher {
    @extend %box-shadow;
    @extend %white-bg;
    @extend %trans3;
    margin-bottom: 30px;
    .thumb {
        img {
            width: 100%;
        }
    }
    .content {
        padding: 22px 30px;

        .position {
            text-transform: uppercase;
            font-size: 13px;
            color: #F79457;
            @extend %heading-font;
            @extend %medium;
            position: relative;
            display: inline-block;
            margin-bottom: 10px;

            &:after {
                background-color: #F79457;
                position: absolute;
                right: -35px;
                top: 44%;
                transform: translateY(-50%);
                content: "";
                width: 20px;
                height: 1px;
            }
        }
        h3  {
            @extend %trans3;
            &:hover {
                color: #F79457;
            }
        }
    }
    &:hover {
        box-shadow: 7px 7px 45px rgba(#000, 0.07);
    }
}