/* ************************
   02.6: Cards
   ********************* */

   /* Card Box Style */
    .card-box-style {
        position: relative;
        .box {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            position: absolute;
            transform: translate3d(0, 0, 0);
            transition: left 500ms cubic-bezier(0.694, 0, 0.335, 1), top 500ms cubic-bezier(0.694, 0, 0.335, 1);
            width: 50%;
            height: 50%;
            z-index: -1;
            // box-shadow: 10px 10px 60px rgba(0, 0, 0, 0.07);
            @extend %box-shadow3;
            background-color: #ffffff;
            border-radius: 5px;
            @include mobileLg {
                width: 100%;
                height: 25%;
                left: 0;
            }
        }

        > div {
            padding: 50px 30px 46px;

            &:nth-child(1),
            &:nth-child(3) {
                border-right: 1px solid rgba(#000000, 0.09);
                @include mobileLg {
                    border: none;
                }
            }
            &:nth-child(1),
            &:nth-child(2) {
                border-bottom: 1px solid rgba(#000000, 0.09);
                @include mobileLg {
                    border: none;
                }
            }

            &:nth-child(1):hover ~ .box {
                left: 0;
            }
            &:nth-child(2):hover ~ .box {
                left: 50%;
                @include mobileLg {
                    top: 25%;
                    left: 0;
                }
            }
            &:nth-child(3):hover ~ .box {
                left: 0;
                top: 50%;
                @include mobileLg {
                    top: 50%;
                }
            }
            &:nth-child(4):hover ~ .box {
                left: 50%;
                top: 50%;
                @include mobileLg {
                    top: 75%;
                    left: 0;
                }
            }
        }
    }


    /* Grid */
    #grid {
        margin: -15px 0;
        @include tab {
            margin: -10px 0;
        }
        .grid-item {
            padding: 15px;
            @include tab {
                padding: 10px;
            }
            width: 20%;
            @include mobileMd {
                width: 100%;
                img {
                    width: 100%;
                }
            }
            &.width2 {
                width: calc(38% + 8px);
                @include mobileMd {
                    width: 100%;
                }
            }
            &.width3 {
                width: calc(22% - 8px);
                @include mobileMd {
                    width: 100%;
                }
            }
        }
    }

    .f-salon-gallery,
    .m-salon-gallery {
        display: flex;
        flex-wrap: wrap;
        .grid-item {
            width: 25%;
            @include mobileMd {
                width: 100%;
                img {
                    width: 100%;
                }
            }
            &.width2 {
                width: 50%;
                @include mobileMd {
                    width: 100%;
                }
            }
            &.style--two {
                background-color: #FFE3EF;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 38px 10px;
                @include mobileMd {
                    min-height: 200px;
                }
            }
            h3 {
                letter-spacing: 2px;
                text-transform: uppercase;
                font-size: 36px;
                &.social-title {
                    @extend %regular;
                    margin-bottom: 32px;
                    @include customMax(1199) {
                        margin-bottom: 20px;
                    }
                    @include tab {
                        font-size: 16px;
                    }
                }
                @include customMax(1199) {
                    font-size: 24px;
                }
                @include tab {
                    font-size: 16px;
                }
                @include mobileMd {
                    font-size: 22px;
                }
            }
        }
    }
    .m-salon-gallery {
        @extend %title-color-bg;
        padding: 15px;
        @include mobileSm {
            padding-left: 0;
            padding-right: 0;
        }
        .grid-item {
            padding: 15px;
            @include tab {
                width: 50%;
                img {
                    width: 100%;
                }
            }
            @include mobileMd {
                width: 100%;
            }
            h3 {
                text-align: center;
                font-size: 30px;
                letter-spacing: 1px;
                line-height: 1.533;
                @extend %white;
                margin-bottom: 20px;
                @include medium {
                    font-size: 24px;
                    margin-bottom: 14px;
                }
            }
            h5 {
                @extend %regular;
                text-transform: uppercase;
                margin-bottom: 30px;
                @extend %white;
                @include medium {
                    margin-bottom: 20px;
                }
            }
            .grid-item-content {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                @include mobileMd {
                    min-height: 260px;
                }
            }
        }
    }