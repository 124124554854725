/* ************************
   02.12: Breadcrumb
   ********************* */

    .page-title-wrap {
        min-height: 540px;
        padding-top: 220px;
        padding-bottom: 100px;
        display: flex;
        align-items: center;
        @include mobileSm {
            background-position: right;
        }
    }

    .page-title {
        padding-top: 5px;
        h2 {
            margin-bottom: 16px;
            font-size: 48px;
            @extend %semi-bold;
        }
        ul {
            li {
                font-size: 18px;
                @extend %heading-font;
                position: relative;
                z-index: 1;
                line-height: 1;
                &:not(:last-child) {
                    padding-right: 15px;
                    margin-right: 15px;
                    &:after {
                        right: -5px;
                        position: absolute;
                        content: '>';
                        top: 0;
                        width: 10px;
                        height: 10px;
                        @extend %c1;
                    }
                }
                &.active {
                    @extend %c1;
                }
            }
        }
    }