/* ************************
   03.2: Footer
   ********************* */

   .footer {
      position: relative;
      @extend %title-color;
      z-index: 1;
      &:after {
         width: 100%;
         height: 100%;
         left: 0;
         top: 0;
         position: absolute;
         content: "";
         z-index: -1;
         background-image: url(../img/bg/footer-overlay.png);
         background-position: center;
         background-repeat: no-repeat;
         background-size: cover;
      }
      .widget {
         margin-bottom: 60px;
         font-size: 18px;
         
        .widget-title {
            margin-bottom: 23px;
         }
      }
      &.style--two {
         &:after {
            background-image: url(../img/bg/footer-overlay2.png);
         }
         .footer-bottom {
            padding: 26px 0 25px;
         }
      }
      &.style--three {
         &:after {
            display: none;
         }
         padding-top: 80px;
         @include tab {
            padding-top: 60px;
         }
         .footer-main {
            margin-bottom: -5px;
            &.style_3 {
               margin-bottom: 15px;
            }
         }
      }
   }

   .footer-main {
      padding: 80px 0 15px;
   }

   .footer-bottom {
      padding: 30px 0;
      position: relative;
      line-height: 1;
      &:after {
         width: 100%;
         height: 1px;
         @extend %white-bg;
         left: 0;
         top: 0;
         opacity: 0.7;
         position: absolute;
         content: "";
      }
      .newsletter-form {
         .theme-input-group {
            .btn {
               padding: 10px 26px 10px 18px;
            }
         }
      }
      &.text-white {
         &:after {
            opacity: 0.2;
         }
         .footer-bottom-content {
            > div {
               &:not(:last-child) {
                  &:after {
                     background-image: url(../img/icon/white-divider.png);
                  }
               }
            }
            .copyright-text {
               @extend %white;
            }
         }
      }
   }

   .footer-bottom-content {
      display: flex;
      align-items: center;
      @include mobileMd {
         flex-direction: column;
      }
      > div {
         &:not(:last-child) {
            position: relative;
            margin-right: 25px;
            padding-right: 25px;
            @include mobileMd {
               padding-right: 0;
               margin-right: 0;
               margin-bottom: 20px;
            }
         }
         &:not(:last-child) {
            &:after {
               position: absolute;
               right: 0;
               top: 50%;
               transform: translateY(-50%);
               position: absolute;
               background-image: url(../img/icon/divider.png);
               background-repeat: no-repeat;
               width: 1px;
               height: 40px;
               content: "";
               @include mobileMd {
                  display: none;
               }
            }
         }
      }
   }
