// Colors
$c1: #F81781;
$c2: #050505;
$title-color: #050505;
$text-color: #414141;
$text-color-deep: #050505;
$border-color: rgba(#000000, 0.09);
$white: #ffffff;
$body_bg: #ffffff;

// Fonts
$heading_font: 'Josefin Sans', sans-serif;
$body_font: 'Open Sans', sans-serif;

// Font Weight
$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;

// Font Size
$base-font-size: 16px;
$h1_font_size: 48px;
$h2_font_size: 42px;
$h3_font_size: 24px;
$h4_font_size: 18px;
$h5_font_size: 16px;
$h6_font_size: 14px;

// Line Height
$base-line-height: calc(28 / 16);
$title-line-height: calc(52 / 42);
