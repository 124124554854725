/* ************************
   03.3: Blog
   ********************* */

    .single-post {
        @extend %box-shadow;
        @extend %white-bg;
        @extend %trans3;
        margin-bottom: 30px;
        .post-thumb-wrap {
            position: relative;

            .posted-on {
                position: absolute;
                left: 30px;
                bottom: 15px;
                width: 50px;
                height: 50px;
                @extend %c1-bg;
                font-size: 10px;
                @extend %semi-bold;
                @extend %radius;
                @extend %white;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                line-height: 1.19;
                letter-spacing: 1px;
                a {
                    @extend %white;
                    display: block;
                    text-align: center;
                }
                .day {
                    display: block;
                    font-size: 16px;
                    @extend %semi-bold;
                    letter-spacing: normal;
                }
            }
        }
        .post-thumbnail {
            img {
                width: 100%;
            }
        }
        .post-content {
            padding: 33px 30px 40px;
            .post-title {
                margin-bottom: 10px;
                @extend %trans3;
                &:hover {
                    @extend %c1;
                }
            }
        }

        &.style--two {
            .post-thumb-wrap {
                .posted-on {
                    background-color: #F79457;
                }
            }
            .post-content {
                .post-title {
                    &:hover {
                        color: #F79457;
                    }
                }
            }
            &:hover {
                box-shadow: 7px 7px 45px rgba(#000, 0.07);
            }
        }

        &.style--three {
            position: relative;
            margin-right: 20px;
            margin-bottom: 50px;
            box-shadow: none;
            &:after {
                height: calc(100% + 20px);
                width: 100%;
                left: auto;
                right: -20px;
                top: 0;
                position: absolute;
                content: "";
                background-color: #F7F7F7;
                z-index: -1;
            }
            .post-thumb-wrap {
                .posted-on {
                    background-color: #6A1A9A;
                    border-radius: 0px;
                }
            }
            .post-content {
                .post-title {
                    &:hover {
                        color: #6A1A9A;
                    }
                }
            }
            &:hover {
                @extend %box-shadow;
            }
        }

        &.style--four {
            position: relative;
            margin-right: 20px;
            margin-bottom: 50px;
            box-shadow: none;
            &:after {
                height: calc(100% + 20px);
                width: 100%;
                left: auto;
                right: -20px;
                top: 0;
                position: absolute;
                content: "";
                background-color: #F7F7F7;
                z-index: -1;
            }
            .post-thumb-wrap {
                .posted-on {
                    background-color: #FF0000;
                    border-radius: 0px;
                }
            }
            .post-content {
                .post-title {
                    &:hover {
                        color: #FF0000;
                    }
                }
            }
            &:hover {
                @extend %box-shadow;
            }
        }

        &:hover {
            box-shadow: 7px 7px 45px rgba(#000, 0.07);
        }
    }

    /* Blog Details */
    .post-details .entry-header .entry-thumbnail {
        margin-bottom: 40px;
    }
    
    .post-details .entry-header .entry-title {
        font-size: 36px;
        line-height: 1.28;
        margin-bottom: 15px;
        font-weight: 900;
    }
    
    .post-details .entry-header .entry-meta {
        margin-bottom: 17px;
    }
    
    .post-details .entry-header .entry-meta li {
        display: inline-block;
        a {
            @extend %text-color;
            &:hover {
                @extend %c1;
            }
        }
    }

    .post-details .entry-header .entry-meta li:not(:last-child) {
        margin-right: 24px;
    }
    
    .post-details .entry-header .entry-meta li span {
        font-weight: 700;
        margin-right: 7px;
        @extend %heading-font;
        @extend %title-color
    }
    
    .post-details .entry-tags ul li:first-child,
    .post-details .entry-categories ul li:first-child {
        font-size: 16px;
        font-weight: 700;
        margin-right: 5px;
        @extend %title-color;
    }
    
    .post-details .entry-tags ul li a,
    .post-details .entry-categories ul li a {
        font-size: 14px;
        line-height: 1.57;
    }
    
    .post-details .entry-tags ul li a:not(:last-child):after,
    .post-details .entry-categories ul li a:not(:last-child):after {
        content: ',';
    }
    
    /* Blog Details Content */
    .entry-content h1,
    .entry-content h2,
    .entry-content h3,
    .entry-content h4,
    .entry-content h5,
    .entry-content h6,
    .entry-content .woocommerce-checkout .form-row.notes label,
    .woocommerce-checkout .form-row.notes .entry-content label,
    .entry-content .login-register-wrapper .tab-btn li,
    .login-register-wrapper .tab-btn .entry-content li,
    .comment-content h1,
    .comment-content h2,
    .comment-content h3,
    .comment-content h4,
    .comment-content h5,
    .comment-content h6,
    .comment-content .woocommerce-checkout .form-row.notes label,
    .woocommerce-checkout .form-row.notes .comment-content label,
    .comment-content .login-register-wrapper .tab-btn li,
    .login-register-wrapper .tab-btn .comment-content li,
    .page--content h1,
    .page--content h2,
    .page--content h3,
    .page--content h4,
    .page--content h5,
    .page--content h6,
    .page--content .woocommerce-checkout .form-row.notes label,
    .woocommerce-checkout .form-row.notes .page--content label,
    .page--content .login-register-wrapper .tab-btn li,
    .login-register-wrapper .tab-btn .page--content li {
        line-height: 1.5;
        margin-bottom: 20px;
    }

    
    .entry-content h1,
    .entry-content h2,
    .entry-content h3,
    .entry-content h4,
    .entry-content h5,
    .entry-content h6 {
        padding-top: 20px;
        margin-bottom: 18px;
    }
    
    .entry-content p,
    .comment-content p,
    .page--content p {
        margin-bottom: 30px;
    }
    
    .entry-content table,
    .comment-content table,
    .page--content table {
        margin-bottom: 30px;
    }
    
    .entry-content select,
    .comment-content select,
    .page--content select {
        margin-bottom: 30px;
    }
    
    .entry-content > *:last-child,
    .comment-content > *:last-child,
    .page--content > *:last-child {
        margin-bottom: 0;
    }
    
    .entry-content a:hover,
    .comment-content a:hover,
    .page--content a:hover {
        text-decoration: underline;
    }
    
    /* Post Author Info */
    .post-author {
        padding: 60px 0 53px;
    }
    
    @media only screen and (max-width: 575px) {
        .post-author {
            flex-direction: column;
            align-items: flex-start !important;
        }
    }
    
    .post-author .author-thumb {
        margin-right: 30px;
    }
    
    @media only screen and (max-width: 575px) {
        .post-author .author-thumb {
            margin-bottom: 20px;
        }
    }
    
    .post-author .author-info h4 {
        font-size: 18px;
        margin-bottom: 5px;
    }
    
    /* Post Navigation */
    .post-navigation {
        .nav-link {
            flex-basis: 50%;
            max-width: 50%;
            padding: 0;
            &:first-child {
                padding-right: 10px;
            }
            &:last-child {
                padding-left: 10px;
                .nav-title {
                    i {
                        margin-right: 0;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    
    .post-navigation .nav-link .nav-title {
        display: block;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 17px;
        @extend %text-color;
        &:hover {
            @extend %c1;
        }
    }
    
    .post-navigation .nav-link .nav-title i {
        margin-right: 10px;
    }
    
    .post-navigation .nav-link .nav-title.text-right i {
        margin-left: 10px;
    }
    
    .post-navigation .nav-link .posted-on {
        margin: 10px 0 2px;
        display: block;
        font-size: 14px;
    }
    
    .post-navigation .nav-link:hover .nav-title {
        opacity: 1;
    }
    
    /* Related Post */
    .related-post h2 {
        font-size: 30px;
        margin-bottom: 30px;
    }
    
    /* Post Comments */
    .comments-area .comments-title {
        margin-bottom: 25px;
    }
    
    .comments-area ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    
    .comments-area .comment .single-comment {
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    
    @media only screen and (max-width: 575px) {
        .comments-area .comment .single-comment {
            flex-direction: column;
        }
    }
    
    .comments-area .comment .single-comment .comment-author-image {
        min-width: 65px;
        margin-right: 30px;
        border-radius: 50%;
        overflow: hidden;
    }
    
    @media only screen and (max-width: 575px) {
        .comments-area .comment .single-comment .comment-author-image {
            margin-bottom: 16px;
        }
    }
    
    .comments-area .comment .single-comment:not(.trackback):not(.pingback) .comment-content {
        max-width: calc(100% - 95px);
        width: 100%;
    }
    
    @media only screen and (max-width: 575px) {
        .comments-area .comment .single-comment:not(.trackback):not(.pingback) .comment-content {
            max-width: 100%;
        }
    }
    
    .comments-area .comment .single-comment .comment-content .author_name {
        margin-right: 10px;
        margin-bottom: 0;
    }
    
    .comments-area .comment .single-comment .comment-content .commented-on {
        font-size: 12px;
    }
    
    .comments-area .comment .single-comment .comment-content p {
        margin: 7px 0 5px;
    }
    
    .comments-area .comment ul.children {
        margin-left: 95px;
    }
    
    @media only screen and (max-width: 575px) {
        .comments-area .comment ul.children {
            margin-left: 30px;
        }
    }
    
    /* Comments Form */
    .comment-respond .comment-reply-title {
        margin-bottom: 20px;
    }
    
    .comment-respond .comment-form .comment-notes {
        margin-bottom: 30px;
    }
    
    .comment-respond .comment-form .entry-content select, .entry-content .comment-respond .comment-form select,
    .comment-respond .comment-form .comment-content select, .comment-content .comment-respond .comment-form select,
    .comment-respond .comment-form .page--content select, .page--content .comment-respond .comment-form select {
        margin-bottom: 35px;
    }

    .comment-respond .comment-form .form-control {
        border: 1px solid #EEEEEE;
        margin-bottom: 20px;
    }
    
    .comment-respond .comment-form .custom-checkbox, .comment-respond .comment-form .color-container {
        margin-bottom: 25px;
    }
    
    .comment-respond p.custom-checkbox, .comment-respond p.color-container {
        display: block;
        white-space: normal;
    }