/* ************************
   04.3: Shop
   ********************* */
   .shop-category {
       padding-left: 30px;
       padding-right: 30px;
   }

    .shop-category-single {
        position: relative;
        img {
            width: 100%;
        }
        &:after {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            position: absolute;
            content: "";
            opacity: 0.3;
            background: transparent;
            background: -moz-linear-gradient(top,  transparent 0%, #050505 100%);
            background: -webkit-linear-gradient(top,  transparent 0%,#050505 100%);
            background: linear-gradient(to bottom,  transparent 0%,#050505 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='transparent', endColorstr='#050505',GradientType=0 );

        }
        .content {
            position: absolute;
            z-index: 1;
            text-align: center;
            left: 0;
            bottom: 60px;
            width: 100%;
            h2 {
                font-size: 36px;
                @extend %white;
                margin-bottom: 24px;
            }
        }
    }

    /* Product */
    .single-product {
        @extend %box-shadow;
        @extend %white-bg;
        @extend %trans3;
        padding: 45px 15px 42px;
        text-align: center;
        margin-bottom: 30px;

        .product-summary {
            margin-top: 45px;
            h4 {
                margin-bottom: 8px;
            }
            .price {
                font-size: 14px;
                .woocommerce-Price-amount {
                    display: flex;
                    justify-content: center;
                }
            }
        }
        .product-top {
            position: relative;
        }

        .buttons {
            display: flex;
            position: absolute;
            top: 54%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            visibility: hidden;
            @extend %trans3;
            .btn-circle {
                &:not(:last-child) {
                    margin-right: 7px;
                }
            }
        }
        &:hover {
            box-shadow: 7px 7px 60px rgba(#000000, 0.07);
           .buttons {
               top: 50%;
               opacity: 1;
               visibility: visible;
           } 
        }
    }
    .star-rating {
        display: flex;
        justify-content: center;
        margin-bottom: 18px;
        font-size: 13px;
        i {
            color: #F8AA17;
            &:not(:last-child) {
                margin-right: 7px;
            }
        }
    }

    /* Woocommerce */
    .woocommerce-tabs {
        .tab-btn {
            margin-bottom: 20px;

            ul {
                background-color: #FAE5EF;
            }

            li {
                button {
                    @extend %title-color;
                    @extend %bold;
                    @extend %heading-font;
                    position: relative;
                    cursor: pointer;
                    @extend %trans3;
                    padding: 6px 18px;
                    @include mobileSm {
                        font-size: 15px;
                    }
                    &.active,
                    &:hover {
                        @extend %c1-bg;
                        @extend %white;
                    }
                }
            }
        }

        .product-description {
            > p {
                margin-bottom: 27px;
            }

            .media {
                img {
                    margin-right: 30px;
                }
            }
            h5 {
                margin-bottom: 15px;
                font-size: 18px;
                line-height: 1.4;
            }
            ul {
                @extend %list-unstyled;
            }
        }

        .product-faq {
            .single-faq {
                position: relative;
                padding-left: 50px;
                @include mobileSm {
                    padding-left: 30px;
                }
                &:not(:last-child) {
                    margin-bottom: 30px;
                }

                .faq-count {
                    position: absolute;
                    left: 0;
                    top: 2px;
                }

                h5 {
                    margin-bottom: 15px;
                    font-size: 18px;
                    line-height: 1.4;
                }
            }
        }

        .woocommerce-Reviews {
            .review-list {
                @extend %list-unstyled;
                li {
                    &:not(:last-child) {
                        margin-bottom: 30px;
                    }
                }
            }
            .single-review {
                .author-image {
                    margin-right: 20px;
                    img {
                        width: 65px;
                        height: 65px;
                    }
                }
                .review-content {
                    .author_name {
                        margin-right: 7px;
                        @include mobileLg {
                            margin-right: 0;
                            margin-bottom: 2px;
                        }
                    }
                    .commented-on {
                        font-size: 12px;
                        margin-right: 20px;
                        @include mobileLg {
                            margin-right: 0;
                            margin-bottom: 5px;
                        }
                    }
                    p {
                        font-size: 14px;
                        margin-top: 5px;
                    }
                }
            }
            .review-form {
                margin-top: 40px;
                background-color: #FEF6F8;
                padding: 40px 45px 45px;
                @include mobileSm {
                    padding: 20px 15px;
                }
                h3 {
                    margin-bottom: 17px;
                }
                .comment-notes {
                    margin-bottom: 22px;
                }
                .comment-form-rating {
                    margin-bottom: 30px;
                    label {
                        margin-bottom: 0;
                        line-height: 1;
                        font-weight: 700;
                        margin-right: 10px;
                        @extend %heading-font;
                    }
                }
                .form-control {
                    margin-bottom: 30px;
                    border: 0;
                }
                textarea.form-control {
                    height: 160px;
                }

                .custom-checkbox {
                    label {
                        margin-bottom: 16px;
                        input {
                            margin-right: 14px;
                        }
                    }
                }
            }

            .star-rating {
                font-size: 10px;
                margin: 0;
                i {
                    &:not(:last-child) {
                        margin-right: 2px;
                    }
                }
            }
        }
    }

    .releted-product {
        .related-product-title {
            font-size: 30px;
            margin-bottom: 26px;
        }
    }

    #gal1 {
        a {
            display: block;
            width: 60px;
            height: 60px;
            overflow: hidden;
            img {
                width: 60px;
                height: 60px;
            }
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    .ez-wrap {
        position: relative;
        display: inline-block;
        .ez-small-img {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
        }
    }

    .product-details-content {
        @include customMin(992) {
            margin-top: -14px;
        }
        .price-review {
            margin-bottom: 7px;
            .star-rating {
                margin-bottom: 0px;
                font-size: 7px;
                width: 50px;
                i {
                    &:not(:last-child) {
                        margin-right: 3px;
                    }
                }
            }
        }
        .product_title {
            margin-bottom: 14px;
        }
        .price {
            font-size: 36px;
            @extend %c1;
            margin-right: 15px;
            position: relative;
            top: -4px;
        }

        > p {
            margin-bottom: 26px;
        }

        .availability {
            display: flex;
            align-items: center;
            margin-bottom: 27px;
            h5 {
                margin-right: 20px;
            }
        }

        .quantity {
            margin-right: 20px;
            h5 {
                margin-right: 20px;
            }
            .input-group {
                align-items: center;
                .form-control {
                    @extend .circle;
                    border-radius: 50% !important;
                    text-align: center;
                    margin-bottom: 0;
                    font-weight: 500 !important;
                    font-size: 16px;
                }
            }

            .plus, .minus {
                display: inline-block;
                user-select: none;
                cursor: pointer;
                &:hover {
                    svg {
                        path {
                            fill: $c1;
                        }
                    }
                }
            }
            .plus {
                margin-left: 10px !important;
            }
            .minus {
                margin-right: 10px !important;
            }
        }

        .cart {
            .buttons {
                margin-bottom: 30px;
                a {
                    &:not(:last-child) {
                        margin-right: 20px;
                    }
                }
            }
        }

        .size-guide {
            margin-bottom: 14px;
        }
    }

    .categories,
    .tags {
        display: flex;
        align-items: center;
        h5 {
            margin-right: 10px;
        }
        a {
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }

    .circle {
        width: 40px;
        max-width: 40px;
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        border: 1px dashed #F81781;
        @extend %radius;
        @extend %medium;
        @extend %c1;
    }