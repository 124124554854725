/* ************************
   02.7: Widget
   ********************* */

    .widget {
        &:not(:last-child) {
            margin-bottom: 45px;
        }
        .widget_title {
            margin-bottom: 20px;
            line-height: 1.2;
        }
        ul {
            @extend %list-unstyled;
        }
        &.widget_newsletter {
            .newsletter-content {
                button {
                    margin-top: 16px;
                    padding: 16px 32px;
                }
                input {
                    background-color: rgba($white, 0.09);
                    border: none;
                    height: 50px;
                    &:focus {
                        @extend %white;
                    }
                    &::placeholder {
                        color: #ffffff !important;
                        opacity: .3;
                    }
                }
                label {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                    margin-top: 16px;
                    @extend %white;
                    @extend %heading-font;
                    font-size: 16px;
                    input[type=checkbox] {
                        margin-right: 10px;
                        height: auto;
                        position: relative;
                        top: -1px;
                    }
                }
            }
            &.style--two {
                .theme-input-group {
                    position: relative;
                    overflow: inherit;
                    margin: 0;
                    .form-control {
                        border: 1px solid #EEEEEE;
                        margin-bottom: 0;
                        padding: 5px 20px;
                        padding-right: 35px;
                        height: 45px;
                    }
                    button {
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
        &.widget_recent_entries {
            ul {
                li {
                    &:not(:last-child) {
                        margin-bottom: 15px;
                    }
                    padding-bottom: 3px;
                    border-bottom: 1px solid #EEEEEE;
                    .posted-by,
                    .posted-on {
                        display: block;
                        @extend %c1;
                        font-size: 12px;
                        margin-bottom: 7px;
                    }
                    .posted-on {
                        text-transform: uppercase;
                        margin-bottom: 4px;
                    }
                    .post-title {
                        a {
                            line-height: 1.4;
                            &:hover {
                                @extend %c1;
                            }
                        }
                    }
                }
            }
        }
        &.widget_tag_cloud {
            .tagcloud {
                margin: 0 -6px -6px 0;
                a {
                    display: inline-block;
                    padding: 5px 8px;
                    background-color: #F9F9F9;
                    margin: 0 3px 6px 0;
                    @extend %title-color;
                    @extend %medium;
                    border-radius: 2px;
                    font-size: 13px;
                    &:hover {
                        @extend %c1;
                    }
                }
            }
        }
        &.widget_nav_menu {
            ul {
                li {
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                    a {
                        text-transform: capitalize;
                        display: block;
                    }
                }
                &.style--two {
                    a {
                        &:hover {
                            color: #F79457;
                        }
                    }
                }
                &.style--three {
                    a {
                        &:hover {
                            color: #6A1A9A;
                        }
                    }
                }
                &.style--four {
                    a {
                        @extend %white;
                        &:hover {
                            color: #FF0000;
                        }
                    }
                }
            }
        }
        &.widget_categories {
            ul {
                li {
                    &:not(:last-child) {
                        margin-bottom: 18px;
                    }
                    a {
                        position: relative;
                        @extend %text-color;
    
                        &:hover {
                            @extend %c1;
                        }
                    }
                }
            }
        }
        &.widget_about {
            p {
                margin-bottom: 27px;
            }
            .contact-list {
                li {
                    position: relative;
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                    i {
                        @extend %c1;
                        font-size: 14px;
                        margin-right: 10px;
                    }
                    span {
                        @extend %c1;
                    }
                }
                &.style--two {
                    li {
                        span {
                            color: #F79457;
                        }
                    }
                }
            }
        }
        &.widget_contact_info {
            p {
                margin-bottom: 27px;
            }
            .contact-list {
                li {
                    padding-left: 20px;
                    position: relative;
                    i {
                        position: absolute;
                        @extend %c1;
                        left: 0;
                        top: 8px;
                        font-size: 14px;
                    }
                }
                &.style--two {
                    li {
                        padding-left: 36px;
                        &:not(:last-child) {
                            margin-bottom: 18px;
                        }
                        i {
                            @extend %title-color;
                        }
                        a {
                            &:hover {
                                color: #F79457;
                            }
                        }
                    }
                }
                &.style--three {
                    li {
                        padding-left: 35px;
                        &:not(:last-child) {
                            margin-bottom: 18px;
                        }
                        i {
                            color: #6A1A9A;
                        }
                        a {
                            &:hover {
                                color: #6A1A9A;
                            }
                        }
                    }
                }
                &.style--four {
                    li {
                        padding-left: 35px;
                        @extend %white;
                        &:not(:last-child) {
                            margin-bottom: 18px;
                        }
                        i {
                            color: #FF0000;
                        }
                        a {
                            @extend %white;
                            &:hover {
                                color: #FF0000;
                            }
                        }
                    }
                }
            }
            .num {
                margin-bottom: 20px;
                display: block;
            }
        }
        &.widget_social_links {
            .socials {
                a {
                    color: #FED0E6;
                    border-color: #FED0E6;
                    &:hover {
                        @extend %c1;
                        border-color: $c1;
                    }
                }
            }
        }
        &.widget_search {
            .theme-input-group {
                position: relative;
            }
            .form-control {
                border: 1px solid #EEEEEE;
                margin-bottom: 0;
                padding: 5px 20px;
                padding-right: 35px;
            }
            button {
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                svg {
                    path {
                        fill: $c1;
                    }
                }
            }
        }
    }

    .service-sidebar {
        .widget {
            padding: 42px 30px 45px;
            // max-width: 350px;
            &:not(:last-child) {
                margin-bottom: 45px;
            }
            &.widget_add {
                padding: 0;
            }
            .widget-title {
                margin-bottom: 10px;
                h3 {
                    font-size: 30px;
                }
            }
            > p {
                font-size: 18px;
                margin-bottom: 27px;
            }

            ul {
                li {
                    font-size: 16px;
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                }
            }
            > .btn {
                margin-top: 31px;
            }
        }
    }